import React from 'react'
import { Box } from '@mui/material'
import { Value, ListItemSkeleton, Label } from './utils'
import EditComment from './EditComment'
import { useTranslation } from 'react-i18next'

const CommentValue = ({
    id,
    value,
    step,
    loading,
    shouldAllowReplies = true,
    commentStepFriendlyName,
    classes,
    editComment,
    setEditComment,
    handleEditComment,
}) => {
    const { t, i18n } = useTranslation()
    const editField = (
        <EditComment
            id={id}
            initialValue={value}
            editComment={editComment}
            setEditComment={setEditComment}
            handleEditComment={handleEditComment}
        />
    )

    const commentStepFriendlyNameLabel = (
        <Box
            display="flex"
            alignItems="center"
            position="absolute"
            right={0}
            bottom={6}
        >
            <Label className={classes.stepLabel}>{step}</Label>
            <Label className={classes.stepValue}>
                {commentStepFriendlyName}
            </Label>
        </Box>
    )

    return (
        <Box
            paddingY="5px"
            position="relative"
            paddingBottom={!shouldAllowReplies ? '24px' : '5px'}
            className={classes.valueContainer}
        >
            {loading ? (
                <ListItemSkeleton animation="wave" variant="rect" />
            ) : editComment ? (
                editField
            ) : (
                <Value className={classes.value}>
                    {i18n.exists(value) ? t(value) : value}
                </Value>
            )}
            {!shouldAllowReplies && commentStepFriendlyNameLabel}
        </Box>
    )
}

export default CommentValue
