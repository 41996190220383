import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Popover, Typography, styled, useTheme } from '@mui/material'
import { Button, DecisionComment } from 'components'
import { Icon } from 'packages/eid-icons'
import { Divider, Spinner, ToggleSwitch } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import {
    mobileScreenWidth,
    getDecisionCommentValidationMessage,
    isDateInPast,
} from 'utils'
import { StartDateTimePicker } from './TimeConstrainedControls/StartDateTimePicker'
import { EndDateTimePicker } from './TimeConstrainedControls/EndDateTimePicker'
import AccessRequestPolicyDetails from './AccessRequestPolicyDetails'
import moment from 'moment'

const PopoverContentContainer = styled(Box)({
    margin: '24px',
    [`@media (min-width:${mobileScreenWidth})`]: {
        minWidth: '400px',
    },
})

const StyledLabel = styled(Box)({
    margin: '24px 0px',
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '204px',
    display: 'block',
    wordBreak: 'break-word',
    fontWeight: 'bold',
})

const WarningLabel = styled(Typography)(({ theme }) => ({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: theme?.palette?.warning?.main,
    margin: '0 0.5rem',
    fontSize: '1.4rem',
}))

const WarningMessage = styled(Typography)({
    fontSize: '1.4rem',
})

const DecisionConfirmationButton = ({
    approvalDecision,
    decisionComment,
    setDecisionComment,
    showTimeConstraintControl = false,
    requireTimeControl = false,
    startDate = null,
    setStartDate = {},
    endDate = null,
    setEndDate = {},
    onSuccess,
    isLoading,
    policy = {},
    handleToggleValue = () => {},
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const [toggleValue, setToggle] = React.useState(
        Boolean(endDate) || requireTimeControl || policy?.isTimeConstrained,
    )

    useEffect(() => {
        setToggle(
            Boolean(endDate) || requireTimeControl || policy?.isTimeConstrained,
        )
    }, [policy])

    const [accessLength, setAccessLength] = React.useState(0)
    useEffect(() => {
        let minuteDifference =
            endDate && startDate
                ? moment(endDate).diff(moment(startDate), 'minutes')
                : 0

        const defaultMinutes = policy?.defaultValueInMinutes
        if (policy?.isTimeConstrained) {
            minuteDifference =
                minuteDifference > 0
                    ? Math.min(defaultMinutes, minuteDifference)
                    : defaultMinutes
        } else {
            minuteDifference =
                minuteDifference > 0 ? minuteDifference : defaultMinutes
        }

        setAccessLength(minuteDifference)
    }, [])

    useEffect(() => {
        handleSelectedStartDate()
        handleToggleValue(toggleValue)
    }, [toggleValue])

    const handleSelectedStartDate = () => {
        if (isDateInPast(startDate) || !startDate) {
            const updatedDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
            setStartDate(updatedDate)
            return
        }
        return
    }

    useEffect(() => {
        handleSelectedEndDate()
    }, [startDate])

    const handleSelectedEndDate = () => {
        if (!startDate || !policy?.defaultValueInMinutes) {
            return ''
        }
        const updatedDate = moment
            .utc(startDate)
            .add(accessLength, 'minute')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')

        setEndDate(updatedDate)
        return
    }
    const handleToggle = () => {
        setToggle((prev) => !prev)
    }

    const ButtonComponent =
        approvalDecision.decisionAction === 'Continue'
            ? Button.Approve
            : Button.Revoke

    const [anchorEl, setAnchorEl] = React.useState(null)

    const open = Boolean(anchorEl)

    const showConfirmation = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDecisionComment('')
    }

    const missingDate =
        (requireTimeControl ||
            (policy?.isTimeConstrained && showTimeConstraintControl)) &&
        (!endDate || !startDate)

    const invalidDate =
        isDateInPast(endDate) || moment(startDate).isAfter(moment(endDate))

    const isRejected = approvalDecision.decisionAction !== 'Continue'
    const notSubmittable =
        !isNilOrEmpty(
            getDecisionCommentValidationMessage(decisionComment, isRejected),
        ) ||
        invalidDate ||
        missingDate

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PopoverContentContainer>
                    <Box marginTop={4} fontWeight="bold">
                        {t('ApprovalConfirmation', {
                            text: approvalDecision.decisionFriendlyName,
                        })}
                        {approvalDecision.decisionAction !== 'Continue' && (
                            <span style={{ color: 'red' }}> * </span>
                        )}
                    </Box>

                    {showTimeConstraintControl && (
                        <>
                            <Box maxWidth={'430px'}>
                                <StyledLabel>
                                    {t('SelectTimeConstraint')}
                                </StyledLabel>
                                <Box minWidth="140px">
                                    <ToggleSwitch
                                        value={toggleValue}
                                        label={t('SetDuration')}
                                        labelColor="#5d6870"
                                        onChange={handleToggle}
                                        disabled={
                                            requireTimeControl ||
                                            policy?.isTimeConstrained
                                        }
                                    />
                                </Box>
                                {toggleValue && (
                                    <>
                                        <Box
                                            display={'flex'}
                                            minWidth={'200px'}
                                        >
                                            <Box
                                                padding={'0px 4px'}
                                                display="flex"
                                                minWidth={'215px'}
                                            >
                                                <Box
                                                    style={{
                                                        alignSelf: 'end',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    <Icon name={'StartTime'} />
                                                </Box>
                                                <StartDateTimePicker
                                                    selectedStartDate={
                                                        startDate
                                                    }
                                                    setSelectedStartDate={
                                                        setStartDate
                                                    }
                                                    showWarningColor={
                                                        isDateInPast(endDate) ||
                                                        invalidDate
                                                    }
                                                />
                                            </Box>

                                            <Box
                                                padding={'0px 4px'}
                                                display="flex"
                                            >
                                                <Box
                                                    style={{
                                                        alignSelf: 'end',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    <Icon name={'EndTime'} />
                                                </Box>
                                                <EndDateTimePicker
                                                    selectedEndDate={endDate}
                                                    setSelectedEndDate={
                                                        setEndDate
                                                    }
                                                    minEndDate={moment(
                                                        startDate,
                                                    )}
                                                    maxEndDate={
                                                        policy?.isTimeConstrained
                                                            ? moment(
                                                                  startDate,
                                                              ).add(
                                                                  policy?.maximumValueInMinutes,
                                                                  'minute',
                                                              )
                                                            : null
                                                    }
                                                    showWarningColor={
                                                        isDateInPast(endDate) ||
                                                        invalidDate
                                                    }
                                                    // hasInvalidDates={hasInvalidDates}
                                                    // validationMessage={invalidDatesMessage}
                                                />
                                            </Box>
                                        </Box>

                                        {isDateInPast(endDate) && (
                                            <Box
                                                marginTop={'1.6rem'}
                                                paddingLeft={'0.4rem'}
                                            >
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                >
                                                    <Icon
                                                        name="Warning"
                                                        color={
                                                            theme?.palette
                                                                ?.warning?.main
                                                        }
                                                        height="1rem"
                                                        width="1rem"
                                                    />
                                                    <WarningLabel>
                                                        {`${t('Warning')}:`}
                                                    </WarningLabel>
                                                </Box>
                                                <Box>
                                                    <WarningMessage>
                                                        {t(
                                                            'TimeConstraintExceededMessage',
                                                        )}
                                                    </WarningMessage>
                                                </Box>
                                            </Box>
                                        )}

                                        <Box marginTop={'2.4rem'}>
                                            <AccessRequestPolicyDetails
                                                policy={policy}
                                                orientation="vertical"
                                                noOfColumns={2}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>

                            <Box margin="24px 0px">
                                <Divider />
                            </Box>
                        </>
                    )}
                    <Box my={3}>
                        <DecisionComment
                            initialValue={decisionComment}
                            onChange={(comment) => setDecisionComment(comment)}
                            onBlur={(e) => {
                                let decisionComment = e.target.value
                                setDecisionComment(decisionComment)
                            }}
                            isRejected={
                                approvalDecision.decisionAction !== 'Continue'
                            }
                        />
                    </Box>
                    <Box my={2} display="flex">
                        <Box>
                            <Button
                                size="medium"
                                disabled={notSubmittable}
                                onClick={() =>
                                    onSuccess().then(() => handleClose())
                                }
                                disabledStyles={{
                                    backgroundColor: '#d9d9d9',
                                }}
                                style={{
                                    backgroundColor: '#307fc1',
                                    '&:hover': {
                                        backgroundColor: '#307fc1',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                {isLoading ? (
                                    <Spinner size={0.6} color="#ffffff" />
                                ) : (
                                    <Icon name="Approve" color="#ffffff" />
                                )}
                            </Button>
                        </Box>
                        <Box mx={2}>
                            <Button
                                size="medium"
                                onClick={handleClose}
                                style={{
                                    backgroundColor: '#aab0b4',
                                    '&:hover': {
                                        backgroundColor: '#aab0b4',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                <Icon
                                    name="Close"
                                    color="#ffffff"
                                    width="14px"
                                    height="14px"
                                />
                            </Button>
                        </Box>
                    </Box>
                </PopoverContentContainer>
            </Popover>
            <ButtonComponent onClick={showConfirmation}>
                {approvalDecision.decisionFriendlyName}
            </ButtonComponent>
        </>
    )
}

export default DecisionConfirmationButton
